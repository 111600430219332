<template>
  <div>
    <b-modal
      id="modal-add-game-type"
      cancel-variant="outline-secondary"
      :ok-title="$t('Add')"
      :cancel-title="$t('Close')"
      ok-variant="success"
      centered
      modal-class="modal-success"
      :title="$t('Add game type')"
      @ok="confirm"
      @hidden="resetModal()"
    >
      <!-- form -->
      <validation-observer ref="addGameTypeModal">
        <b-form method="POST" @submit.prevent="validationForm">
          <b-form-group label-for="name">
            <label class="mb-1">{{ $t("Name") }}</label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="gameType.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="code">
            <label class="mb-1">{{ $t("Code") }}</label>
            <validation-provider
              #default="{ errors }"
              name="code"
              rules="required"
            >
              <b-form-input
                id="code"
                v-model="gameType.code"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="type">
            <label class="mb-1">{{ $t("Category") }}</label>
            <validation-provider
              #default="{ errors }"
              name="category"
              rules="required"
            >
              <v-select
                v-model="gameType.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="gameType.typeOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="Select type"
                label="name"
                input-id="type"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      gameType: {
        name: "",
        code: "",
        type: "",
      },
    };
  },
  methods: {
    validationForm() {
      this.$refs.addGameTypeModal.validate().then((success) => {
        if (success) {
          // this.addGameType(this.gameType)
        }
      });
    },
    /* async addGameType(postData) {
			await this.$store.dispatch('frontend/addPost', postData)
				.then(response => {
					if (response.status === 200) {
						if (response.data.code == '00') {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'success',
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
                            this.$emit('refetch-data')
							this.$nextTick(() => {
								setTimeout(() => {
									this.$bvModal.hide('modal-add-post')
									this.resetModal()
								}, '500')
							})
							this.$emit('refetch-data')
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
					}
				})
		}, */
    resetModal() {
      this.gameType = {
        name: "",
        code: "",
        type: "",
      };
    },
    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    return {};
  },
};
</script>